import React, { Component } from 'react';
import styled from 'styled-components';

import FormStyle from '../../styles/formStyle';

const StyledForm = styled(FormStyle)`
	color: ${(props) => props.theme.colors.lightest};

	h2 {
		color: ${(props) => props.theme.colors.lightest};
		text-transform: none;
	}

	.control {
		@media (min-width: 769px) {
			margin-right: 4rem;
		}
	}

	label {
		display: block;
		text-align: left;
	}

	input,
	textarea,
	select {
		border-bottom-color: ${(props) => props.theme.colors.lightest};
		color: ${(props) => props.theme.colors.lightest};

		&::placeholder {
			color: ${(props) => props.theme.colors.lightest};
		}
	}

	option {
		background-color: ${(p) => p.theme.colors.secondary};
	}


	[type='submit'] {
		color: ${(props) => props.theme.colors.lightest};
		border-color: ${(props) => props.theme.colors.lightest};

		&:hover {
			/* color: ${(props) => props.theme.colors.secondary};
			background-color: ${(props) => props.theme.colors.lightest}; */
		}

		&::before {
			background-color: ${(props) => props.theme.colors.lightest};
		}

		&::after {
			border-right-color: ${(props) => props.theme.colors.lightest};
			border-bottom-color: ${(props) => props.theme.colors.lightest};
		}

		@media (min-width: 769px) {
			margin-right: 6rem;
		}
	}
`;

function encode(data) {
	return Object.keys(data)
		.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&');
}

export default class CareersForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isValidated: false,
			isSubmitted: false,
			referralNotesOpen: false,
		};
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const form = e.target;
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': form.getAttribute('name'),
				...this.state,
			}),
		})
			.then(this.setState({ isSubmitted: true }))
			.catch((error) => alert(error));
	};

	render() {
		let formName = 'Careers';
		let id = 'careers-form';

		if (!!this.state.isSubmitted) {
			return (
				<div className="columns is-centered">
					<div className="column is-narrow has-text-white">
						<h2 className="has-text-centered">Thanks!</h2>
						<p className="has-text-centered">
							We have received your submission.
						</p>
					</div>
				</div>
			);
		} else {
			return (
				<StyledForm
					name={formName}
					method="post"
					action="/contact/thanks/"
					data-netlify="true"
					data-netlify-honeypot="bot-field"
					onSubmit={this.handleSubmit}
					id={id}
				>
					{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
					<input type="hidden" name="form-name" value={'Careers'} />
					<div hidden>
						<label>
							Don’t fill this out:{' '}
							<input name="bot-field" onChange={this.handleChange} />
						</label>
					</div>
					<div className="columns">
						<div className="column">
							<div className="control">
								<input
									type={'text'}
									name={'first-name'}
									onChange={this.handleChange}
									id={'first-name'}
									required={true}
									placeholder={'Jane'}
								/>
							</div>
							<label htmlFor={'first-name'}>First Name</label>
						</div>
						<div className="column">
							<div className="control">
								<input
									type={'text'}
									name={'last-name'}
									onChange={this.handleChange}
									id={'last-name'}
									required={true}
									placeholder={'Smith'}
								/>
							</div>
							<label htmlFor={'last-name'}>Last Name</label>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="control">
								<input
									type={'email'}
									name={'email'}
									onChange={this.handleChange}
									id={'email'}
									required={true}
									placeholder={'janesmith@email.com'}
								/>
							</div>
							<label htmlFor={'email'}>Email</label>
						</div>
						<div className="column">
							<div className="control">
								<input
									type={'text'}
									name={'phone'}
									onChange={this.handleChange}
									id={'phone'}
									placeholder={'(705) 555-5555'}
								/>
							</div>
							<label htmlFor={'phone'}>Phone</label>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="control">
								<textarea
									name={'message'}
									onChange={this.handleChange}
									id={'message'}
									required={true}
									rows="3"
									placeholder={'Your Message'}
								/>
							</div>
							<label htmlFor={'message'}>Message</label>
						</div>
						<div className="column"></div>
					</div>
					<div
						className="columns"
						css={`
							@media (max-width: 767px) {
								display: flex;
								flex-direction: column-reverse !important;
							}
						`}
					>
						<div className="column is-narrow">
							<button className="button button-1" type="submit">
								Submit
							</button>
						</div>
					</div>
				</StyledForm>
			);
		}
	}
}
