import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Modal from './Modal';
import TeamContactForm from '../forms/TeamContactForm';
import CloseIcon from './CloseIcon';

const StyledModalContent = styled.div`
	position: relative;

	.modal-content-upper {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 70px 16px 50px;
		text-align: center;
		padding-bottom: 0;

		@media (max-width: 768px) {
			padding-top: 60px;
		}

		@media (max-width: 599px) {
			h2 {
				font-size: 2.3rem;
			}
		}

		h2 {
			font-family: ${(p) => p.theme.fonts.secondary};
			font-size: 3rem;
			line-height: 1.5;
			text-transform: none;
			max-width: 17em;
			margin: 0 auto 0.5em;
			color: inherit;
		}

		p {
			font-size: 2rem;
			line-height: 1.5;

			@media (max-width: 768px) {
				font-size: 1.8rem;
			}
		}
	}

	.team-contact-card {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-gap: 2.5rem;
	}

	.team-member-image-wrapper {
		width: 97px;
		height: 97px;
		overflow: hidden;
		border-radius: 50%;
		box-shadow: 0px 3px 9px #00000065;
	}

	.team-member-text-wrapper {
		text-align: left;
	}

	.team-member-contact-heading {
		text-transform: uppercase;
		color: ${({ theme }) => theme.colors.primary};
		font-size: 1.6rem !important;
		margin-bottom: 0.3em;
	}

	.team-member-name {
		font-size: 2rem;
		font-weight: ${({ theme }) => theme.fonts.weights.bold};
		text-transform: uppercase;
		margin-bottom: 0.5em;
		line-height: 1 !important;
	}

	.team-member-title {
		font-family: ${({ theme }) => theme.fonts.secondary};
		font-size: 1.8rem;
		color: ${({ theme }) => theme.colors.tertiary};
		margin-top: 0;
		margin-bottom: 2rem;
		line-height: 1 !important;
	}

	.modal-content-lower {
		padding: 2.5rem 1.6rem 5rem;

		@media (max-width: 768px) {
			padding-top: 0;
			padding-bottom: 3rem;
		}

		.content-wrapper {
			max-width: 400px;
			margin: 0 auto;
		}
	}

	.close-modal {
		position: absolute;
		top: 1.7rem;
		right: 2rem;
		border: none;
		outline: none;
		background: rgba(255, 255, 255, 0);
		cursor: pointer;
		width: 3rem;
		height: 3rem;
		padding: 0;

		svg {
			width: 100%;
			height: auto;

			rect {
				fill: ${({ theme }) => theme.colors.secondary};
			}
		}
	}
`;

const style = {
	overlay: {
		zIndex: '100',
		padding: '5.5rem 2.7rem',
		backgroundColor: 'rgba(27,27,27, 0.8)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	content: {
		position: 'static',
		borderRadius: '0',
		border: '1px solid #707070',
		padding: '0',
		width: '100%',
		maxWidth: '980px',
		maxHeight: '100%',
	},
};

const TeamMemberContactModal = ({ teamMember, isOpen, onClose }) => {
	const [isModalOpen, setIsModalOpen] = useState(isOpen);

	useEffect(() => {
		if (teamMember) {
			setIsModalOpen(true);
		}
	}, [teamMember]);

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);

		if (isModalOpen) {
			onClose();
		}
	};

	return (
		<Modal isOpen={isModalOpen} style={style}>
			<StyledModalContent>
				<button className="close-modal" onClick={() => toggleModal()}>
					<span className="visually-hidden">Close This Popup</span>
					<CloseIcon />
				</button>
				<div className="modal-content-upper">
					{teamMember && teamMember.frontmatter.name ? (
						<div className="team-contact-card">
							{teamMember.frontmatter.image && (
								<div className="team-member-image-wrapper">
									<img
										src={teamMember.frontmatter.image}
										alt={teamMember.frontmatter.name}
									/>
								</div>
							)}
							<div className="team-member-text-wrapper">
								<p className="team-member-contact-heading">Contact</p>
								<p className="team-member-name">
									{teamMember.frontmatter?.name}
								</p>
								<p className="team-member-title">
									{teamMember.frontmatter?.title}
								</p>
							</div>
						</div>
					) : null}
				</div>
				<div className="modal-content-lower">
					<div className="content-wrapper">
						<TeamContactForm teamMember={teamMember} />
					</div>
				</div>
			</StyledModalContent>
		</Modal>
	);
};

export default TeamMemberContactModal;
