import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import AccentLine from '../components/AccentLine';
import CareersForm from '../components/forms/CareersContact';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import TeamMemberContactModal from '../components/modal/TeamMemberContactModal';

import StyledIntro from '../styles/StyledIntro';
import theme from '../styles/theme/theme';

import mdToHtml from '../util/mdToHtml';

import downArrowBlack from '../../static/media/ourteam_arrow.png';

const StyledOurTeamPage = styled.article`
	.two-column-section {
		padding-top: 9rem;
		padding-bottom: 9rem;

		.grid {
			display: grid;
			grid-template-columns: 1.2fr 1fr;
			column-gap: 50px;

			.content-container {
				max-width: 44rem;
				margin-bottom: 3rem;

				@media (min-width: 769px) {
					margin-bottom: 0;
				}

				.heading {
					margin-bottom: 1em;
					color: ${(p) => p.theme.colors.tertiary};
				}
			}
		}

		@media (max-width: 999px) {
			padding-bottom: 3.5rem;

			.grid {
				grid-template-columns: 1fr;
				grid-template-rows: auto 1fr;
				row-gap: 32px;
			}
		}
	}

	.team-section {
		padding-top: 45px;

		@media (min-width: 769px) {
			padding-top: 60px;
		}

		h2 {
			font-size: 2rem;
			color: ${({ theme }) => theme.colors.tertiary};
			text-align: center;
			margin-bottom: 4rem;

			@media (min-width: 769px) {
				margin-bottom: 60px;
			}
		}
	}

	.team-grid {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin: 0 -12px;
		align-items: center;

		@media (min-width: 769px) {
			flex-direction: row;
			justify-content: center;
			align-items: flex-start;
			margin-bottom: 25px;
		}

		.team-member-item {
			flex: 1 1 350px;
			max-width: 350px;
			margin: 12px;
		}

		&.reps {
			.team-member-item {
				flex: 1 1 312px;
				max-width: 312px;
				margin: 12px;
			}
			@media (max-width: 768px) {
				.team-member-item {
					flex: 1 1 350px;
					max-width: 350px;
					margin: 12px;
				}
			}
		}
	}

	.careers-section {
		background-color: ${({ theme }) => theme.colors.lightBgGrey};
		position: relative;

		@media (max-width: 768px) {
			padding-bottom: 6rem;
		}
	}

	.arrow-down {
		position: absolute;
		left: 50%;
		bottom: -4rem;
		width: 40px;
		height: auto;
		transform: translateX(-50%);

		@media (max-width: 768px) {
			bottom: -3rem;
			width: 30px;
		}
	}

	.careers-form-section {
		color: ${({ theme }) => theme.colors.lightest};
		padding-top: 10rem;
		padding-bottom: 15rem;
		background-color: ${({ theme }) => theme.colors.tertiary};

		@media (max-width: 768px) {
			padding-top: 5rem;
			padding-bottom: 10rem;
		}

		h2 {
		}
	}
`;

const TeamMemberWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	text-align: center;

	.team-member-image-wrapper {
		margin-bottom: 2rem;
	}

	.team-member-item-bottom {
		padding-bottom: 15px;
	}

	.team-member-name {
		font-size: 2rem;
		font-weight: ${({ theme }) => theme.fonts.weights.bold};
		text-transform: uppercase;
		margin-bottom: 0.1em;
	}

	.team-member-title {
		font-family: ${({ theme }) => theme.fonts.secondary};
		font-size: 1.8rem;
		color: ${({ theme }) => theme.colors.tertiary};
		margin-top: 0;
		margin-bottom: 2rem;
	}
`;

const TeamMemberCard = ({ teamMember, clickHandler }) => {
	const memberImage = {
		image: teamMember.frontmatter.image,
	};
	return (
		<TeamMemberWrapper className="team-member-item">
			{memberImage.image && (
				<div className="team-member-image-wrapper">
					<div className="image-shadow">
						<PreviewCompatibleImage imageInfo={memberImage} />
					</div>
				</div>
			)}
			<div className="team-member-item-bottom">
				<p className="team-member-name">{teamMember.frontmatter?.name}</p>
				<p className="team-member-title">{teamMember.frontmatter?.title}</p>
				<button
					className="button button-2"
					onClick={() => clickHandler(teamMember.id)}
				>
					Contact
				</button>
			</div>
		</TeamMemberWrapper>
	);
};

export const OurTeamPageTemplate = ({ page, leadership, team }) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [contactMember, setContactMember] = useState(null);

	const allTeamMembers = leadership.concat(team);

	const handleContactClick = (id) => {
		allTeamMembers.forEach(({ node: item }) => {
			if (id === item.id) {
				setContactMember(item);
			}
		});
	};

	const chunks = Array.from({ length: Math.ceil(team.length / 4) }, (v, i) =>
		team.slice(i * 4, i * 4 + 4)
	);

	return (
		<StyledOurTeamPage>
			<StyledIntro
				className="section intro-section"
				color={theme.colors.tertiary}
			>
				<div className="container">
					<div className="grid two-columns">
						<div className="grid-column">
							<div className="intro-content-container">
								<h1 className="heading">Our Real Estate Agents</h1>
								<AccentLine color={theme.colors.primary} />
								<div className="intro-image-wrapper mobile">
									<Img
										fluid={
											page.masthead_section.masthead_image.image.childImageSharp
												.fluid
										}
										alt={page.masthead_section.masthead_image.alt}
									/>
								</div>
								<div
									className="intro-text"
									dangerouslySetInnerHTML={{
										__html: mdToHtml(page.masthead_section.masthead_text),
									}}
								/>
							</div>
						</div>
						<div className="grid-column">
							<div className="intro-image-wrapper desktop">
								<Img
									fluid={
										page.masthead_section.masthead_image.image.childImageSharp
											.fluid
									}
									alt={page.masthead_section.masthead_image.alt}
								/>
							</div>
						</div>
					</div>
				</div>
			</StyledIntro>
			<section className="section team-section">
				<div className="container">
					<h2>{page.team_section.team_heading}</h2>
					<div className="team-grid">
						{leadership.map(({ node }) => (
							<TeamMemberCard
								teamMember={node}
								clickHandler={handleContactClick}
								key={node.id}
							/>
						))}
					</div>
					{chunks.map((chunk) => (
						<div className="team-grid reps">
							{chunk.map(({ node }) => (
								<TeamMemberCard
									teamMember={node}
									clickHandler={handleContactClick}
									key={node.id}
								/>
							))}
						</div>
					))}
				</div>
			</section>
			<section className="section two-column-section careers-section">
				<div className="container">
					<div className="grid">
						<div className="grid-column">
							<div className="content-container">
								<h2 className="heading heading-style-2">
									{page.careers_section.careers_heading}
								</h2>
								<div
									dangerouslySetInnerHTML={{
										__html: mdToHtml(page.careers_section.careers_text),
									}}
								/>
							</div>
						</div>
						<div className="grid-column">
							<div className="image-shadow" style={{ display: 'block' }}>
								<Img
									fluid={
										page.careers_section.careers_image.image.childImageSharp
											.fluid
									}
									alt={page.careers_section.careers_image.alt}
								/>
							</div>
						</div>
					</div>
				</div>
				<img className="arrow-down" src={downArrowBlack} />
			</section>
			<section className="section careers-form-section">
				<div className="container">
					<div className="columns is-centered">
						<div className="column has-text-centered">
							<h2
								className={'heading-style-2'}
								style={{ color: theme.colors.lightest, marginBottom: '1.5em' }}
							>
								{page.careers_form_heading}
							</h2>
							<CareersForm />
						</div>
					</div>
				</div>
			</section>
			<div style={{ display: 'none' }}>
				{/* hard-coding this version of the team member contact form so Netlify will pick it up */}
				<form
					name="Contact Team Member Form"
					method="post"
					action="/contact/thanks/"
					data-netlify="true"
					data-netlify-honeypot="bot-field"
					id="contact-team-member-form"
				>
					<input
						type="hidden"
						name="form-name"
						value="Contact Team Member Form"
					/>
					<input type="hidden" name="team-member" value="" />
					<div hidden="">
						<label>
							Don’t fill this out: <input name="bot-field" />
						</label>
					</div>
					<div class="columns">
						<div class="column">
							<div class="control">
								<input
									type="text"
									name="first-name"
									id="first-name"
									required=""
									placeholder="First Name *"
								/>
							</div>
							<label class="visually-hidden" for="first-name">
								First Name*
							</label>
						</div>
					</div>
					<div class="columns">
						<div class="column">
							<div class="control">
								<input
									type="text"
									name="last-name"
									id="last-name"
									required=""
									placeholder="Last Name *"
								/>
							</div>
							<label class="visually-hidden" for="last-name">
								Last Name*
							</label>
						</div>
					</div>
					<div class="columns">
						<div class="column">
							<div class="control">
								<input
									type="email"
									name="email"
									id="email"
									required=""
									placeholder="Email *"
								/>
							</div>
							<label class="visually-hidden" for="email">
								Email*
							</label>
						</div>
					</div>
					<div class="columns">
						<div class="column">
							<div class="control">
								<input
									type="tel"
									name="phone"
									id="phone"
									placeholder="Phone *"
									required=""
								/>
							</div>
							<label class="visually-hidden" for="email">
								Phone Number*
							</label>
						</div>
					</div>
					<div class="columns">
						<div class="column">
							<div class="control">
								<textarea
									name="message"
									id="message"
									placeholder="Message *"
									required=""
									rows="5"
								></textarea>
							</div>
							<label class="visually-hidden" for="email">
								Your Message*
							</label>
						</div>
					</div>
					<div class="columns is-centered">
						<div class="column is-narrow">
							<button class="button button-4" type="submit">
								Submit
							</button>
						</div>
					</div>
				</form>
			</div>
			<TeamMemberContactModal
				teamMember={contactMember}
				isOpen={modalOpen}
				onClose={() => setContactMember(null)}
			/>
		</StyledOurTeamPage>
	);
};

const OurTeamPage = ({ data }) => {
	const { frontmatter } = data.pageContent;
	const { leadership, team } = data;

	return (
		<Layout page={`our-team`}>
			<SEO
				title={frontmatter.page_title}
				description={"Meet the Real Estate Agents of Parker Coulter Realty. An Experienced Group of Top-Producing Real Estate Agents In Barrie, Orillia, Innisfil, & Surrounding Simcoe-Muskoka Region."}
			/>
			<OurTeamPageTemplate
				page={frontmatter}
				leadership={leadership.edges}
				team={team.edges}
			/>
		</Layout>
	);
};

OurTeamPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default OurTeamPage;

export const ourTeamPageQuery = graphql`
	query OurTeamPage($id: String!) {
		pageContent: markdownRemark(id: { eq: $id }) {
			frontmatter {
				page_title
				meta_description
				page_heading
				masthead_section {
					masthead_text
					masthead_image {
						image {
							childImageSharp {
								fluid(maxWidth: 700) {
									...GatsbyImageSharpFluid
								}
							}
						}
						alt
					}
				}
				team_section {
					team_heading
				}
				careers_section {
					careers_heading
					careers_text
					careers_image {
						image {
							childImageSharp {
								fluid(maxWidth: 700) {
									...GatsbyImageSharpFluid
								}
							}
						}
						alt
					}
				}
				careers_form_heading
			}
		}
		leadership: allMarkdownRemark(
			filter: {
				frontmatter: { isLeadershipTeam: { eq: true }, isVisible: { eq: true } }
			}
			sort: { fields: frontmatter___date, order: ASC }
		) {
			edges {
				node {
					id
					frontmatter {
						name
						title
						image
					}
				}
			}
		}
		team: allMarkdownRemark(
			filter: {
				frontmatter: { isLeadershipTeam: { ne: true }, isVisible: { eq: true } }
			}
			sort: { fields: frontmatter___date, order: ASC }
		) {
			edges {
				node {
					id
					frontmatter {
						name
						title
						image
					}
				}
			}
		}
	}
`;
