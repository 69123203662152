import React, { Component } from 'react';
import styled from 'styled-components';

import FormStyle from '../../styles/formStyle';

const StyledForm = styled(FormStyle)`
	color: ${(props) => props.theme.colors.secondary};
	width: 50rem;
	max-width: 100%;

	h2 {
		font-size: 3rem;
		text-transform: none;
	}

	[class*='AccentLine'] {
		margin-top: 2rem;
	}

	input,
	textarea {
		border-bottom-color: ${(props) => props.theme.colors.secondary};
		color: ${(props) => props.theme.colors.secondary};
	}

	::placeholder {
		color: ${(props) => props.theme.colors.textDarkerGrey};
	}

	[type='submit'] {
		border-color: ${(props) => props.theme.colors.primary};

		&::before {
			display: none;
		}

		&::after {
			display: none;
		}
	}
`;

function encode(data) {
	return Object.keys(data)
		.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&');
}

export default class TeamContactForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isValidated: false,
			isSubmitted: false,
		};
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleSubmit = (e) => {
		e.preventDefault();
		const form = e.target;

		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': form.getAttribute('name'),
				...this.state,
				'team-member': this.props.teamMember.frontmatter.name,
			}),
		})
			.then(this.setState({ isSubmitted: true }))
			.catch((error) => alert(error));
	};

	render() {
		if (!!this.state.isSubmitted) {
			return (
				<div
					className="columns is-centered"
					style={{ height: '150px', alignItems: 'center' }}
				>
					<div className="column is-narrow">
						<h2 className="has-text-centered" style={{ marginBottom: '0.5em' }}>
							Thanks!
						</h2>
						<p className="has-text-centered">
							We have received your submission.
						</p>
					</div>
				</div>
			);
		} else {
			return (
				<StyledForm
					name="Contact Team Member Form"
					method="post"
					action="/contact/thanks/"
					data-netlify="true"
					data-netlify-honeypot="bot-field"
					onSubmit={this.handleSubmit}
					id={'contact-team-member-form'}
				>
					{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
					<input
						type="hidden"
						name="form-name"
						value="Contact Team Member Form"
					/>
					<input
						type="hidden"
						name="team-member"
						value={this.props.teamMember?.frontmatter?.name ?? ''}
					/>
					<div hidden>
						<label>
							Don’t fill this out:{' '}
							<input name="bot-field" onChange={this.handleChange} />
						</label>
					</div>
					<div className="columns">
						<div className="column">
							<div className="control">
								<input
									type={'text'}
									name={'first-name'}
									onChange={this.handleChange}
									id={'first-name'}
									required={true}
									placeholder={'First Name *'}
								/>
							</div>
							<label className="visually-hidden" htmlFor={'first-name'}>
								First Name*
							</label>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="control">
								<input
									type={'text'}
									name={'last-name'}
									onChange={this.handleChange}
									id={'last-name'}
									required={true}
									placeholder={'Last Name *'}
								/>
							</div>
							<label className="visually-hidden" htmlFor={'last-name'}>
								Last Name*
							</label>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="control">
								<input
									type={'email'}
									name={'email'}
									onChange={this.handleChange}
									id={'email'}
									required={true}
									placeholder={'Email *'}
								/>
							</div>
							<label className="visually-hidden" htmlFor={'email'}>
								Email*
							</label>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="control">
								<input
									type={'tel'}
									name={'phone'}
									onChange={this.handleChange}
									id={'phone'}
									placeholder={'Phone *'}
								/>
							</div>
							<label className="visually-hidden" htmlFor={'email'}>
								Phone Number*
							</label>
						</div>
					</div>
					<div className="columns">
						<div className="column">
							<div className="control">
								<textarea
									name={'message'}
									id={'message'}
									onChange={this.handleChange}
									placeholder={'Message *'}
									required={true}
									rows={'5'}
								/>
							</div>
							<label className="visually-hidden" htmlFor={'email'}>
								Your Message*
							</label>
						</div>
					</div>
					<div className="columns is-centered">
						<div className="column is-narrow">
							<button className="button button-4" type="submit">
								Submit
							</button>
						</div>
					</div>
				</StyledForm>
			);
		}
	}
}
